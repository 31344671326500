import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { AppRoutes } from "@unified-trials/arcane-ui-tool";

import { NavItem } from "../layout";


export const DashboardPage = () => {
	const applicationConfigString =
		window.localStorage.getItem("applications") ?? "";

	const theme = useTheme();
	const isScreenSizeSx = useMediaQuery(theme.breakpoints.between("xs", "sm"));
	const direction = isScreenSizeSx ? "column" : "row";

	const appRoutes = AppRoutes(applicationConfigString, false);

	return (
		<Grid container direction={direction} alignItems="center" p={4}>
			{appRoutes.map(({ path, title, icon }) => (
				<Box key={title} sx={{ mr: { xs: 0, sm: 1 }, mb: { xs: 1, sm: 0 } }}>
					<NavItem key={title} path={path} title={title} icon={icon} />
				</Box>
			))}
		</Grid>
	);
};
