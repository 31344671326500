import { ThemeProvider } from "@mui/material/styles";
import { ErrorBoundaryPage } from "@unified-trials/arcane-ui-tool";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import Root from "./root.component";
import theme from "./theme";

const lifecycles = singleSpaReact({
	React,
	ReactDOM,
	rootComponent: Root,
	errorBoundary() {
		return (
			<ThemeProvider theme={theme}>
				<ErrorBoundaryPage fallbackHref="/health-probes" />
			</ThemeProvider>
		);
	},
});

export const { bootstrap, mount, unmount } = lifecycles;
